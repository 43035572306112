import { MxmProProcessEnv } from "@mxmdev/pro-shared";
import {
  GenericError,
  RitmoProvider,
} from "@mxmdev/react-universal-components";
import {
  getServerSideAuthProps,
  withAuthPage,
} from "@mxmdev/react-universal-core";
import { initI18n } from "@mxmdev/react-universal-i18n/pro";
import { useLink } from "@mxmdev/react-universal-navigation";
import dynamic from "next/dynamic";

import {
  ACCOUNT_URL,
  API_ORIGIN,
  API_URL_SIGNATURE_ENABLED,
  APP_ID,
  APP_URL,
  AUTH_CAPTCHA_URL,
  AUTH_SHARED_SIGN_IN_ENABLED,
  AUTH_SIGN_IN_URL,
  AUTH_SIGN_OUT_URL,
  AUTH_TOKEN_MIGRATE_URL,
  CLIENT_KEY,
  COM_URL,
  CURATOR_URL,
  DATO_CMS_API_TOKEN,
  DEPLOY_ENV,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_CROSS_API_KEY,
  FIREBASE_CROSS_APP_ID,
  FIREBASE_CROSS_AUTH_DOMAIN,
  FIREBASE_CROSS_MEASUREMENT_ID,
  FIREBASE_CROSS_MESSAGING_SENDER_ID,
  FIREBASE_CROSS_PROJECT_ID,
  FIREBASE_CROSS_STORAGE_BUCKET_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET_ID,
  INTERCOM_APP_ID,
  MIXPANEL_PRO_PROJECT_TOKEN,
  PUBLISHER_URL,
  SENTRY_DSN,
  SENTRY_RELEASE_VERSION,
} from "../processEnv";

const deployEnv = DEPLOY_ENV as MxmProProcessEnv["deployEnv"];

initI18n({
  initOptions: {
    lng: deployEnv === "prod" ? "en" : undefined,
  },
});

// TODO: remove dynamic import when SSR will work 100%.
const App = dynamic(() => import("../App"), {
  ssr: false,
});

const Index = () => {
  return (
    <App
      accountUrl={ACCOUNT_URL!}
      analyticsConfig={{
        firebase: [
          {
            apiKey: FIREBASE_API_KEY,
            appId: FIREBASE_APP_ID,
            authDomain: FIREBASE_AUTH_DOMAIN,
            measurementId: FIREBASE_MEASUREMENT_ID,
            messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
            projectId: FIREBASE_PROJECT_ID,
            storageBucket: FIREBASE_STORAGE_BUCKET_ID,
          },
          {
            apiKey: FIREBASE_CROSS_API_KEY,
            appId: FIREBASE_CROSS_APP_ID,
            authDomain: FIREBASE_CROSS_AUTH_DOMAIN,
            measurementId: FIREBASE_CROSS_MEASUREMENT_ID,
            messagingSenderId: FIREBASE_CROSS_MESSAGING_SENDER_ID,
            projectId: FIREBASE_CROSS_PROJECT_ID,
            storageBucket: FIREBASE_CROSS_STORAGE_BUCKET_ID,
          },
        ],
        intercom: {
          appId: INTERCOM_APP_ID,
        },
        mixpanel: {
          projectToken: MIXPANEL_PRO_PROJECT_TOKEN,
        },
      }}
      apiOrigin={API_ORIGIN!}
      apiURLSignatureEnabled={API_URL_SIGNATURE_ENABLED === "true"}
      appId={APP_ID!}
      appUrl={APP_URL!}
      captchaChallengeURL={AUTH_CAPTCHA_URL || undefined}
      clientKey={CLIENT_KEY}
      comUrl={COM_URL!}
      curatorUrl={CURATOR_URL!}
      datoApiToken={DATO_CMS_API_TOKEN}
      deployEnv={deployEnv}
      errorTrackingConfig={{ url: SENTRY_DSN, version: SENTRY_RELEASE_VERSION }}
      publisherUrl={PUBLISHER_URL!}
      signInURL={AUTH_SIGN_IN_URL || undefined}
      signOutURL={AUTH_SIGN_OUT_URL || undefined}
    />
  );
};

export const getServerSideProps =
  AUTH_SHARED_SIGN_IN_ENABLED === "true"
    ? getServerSideAuthProps(
        async (_ctx, auth) => {
          return {
            props: {
              auth,
            },
          };
        },
        {
          apiOrigin: API_ORIGIN!,
          appId: APP_ID!,
          forceSignIn: false,
        }
      )
    : async () => ({ props: {} });

const Error = () => (
  <RitmoProvider useLink={useLink}>
    <GenericError />
  </RitmoProvider>
);

export default AUTH_SHARED_SIGN_IN_ENABLED === "true"
  ? withAuthPage(Index, Error, {
      accountURL: ACCOUNT_URL!,
      apiOrigin: API_ORIGIN!,
      appId: APP_ID!,
      clientKey: CLIENT_KEY!,
      signInURL: AUTH_SIGN_IN_URL!,
      signOutURL: AUTH_SIGN_OUT_URL!,
      tokenMigrateURL: AUTH_TOKEN_MIGRATE_URL!,
    })
  : Index;
