import { NativeModules, Platform } from "react-native";

export const getDeviceLanguage = (): string | undefined => {
  let deviceLanguage: string | undefined = undefined;

  if (Platform.OS === "ios") {
    deviceLanguage =
      NativeModules.SettingsManager.settings.AppleLocale ||
      NativeModules.SettingsManager.settings.AppleLanguages[0];
  }

  if (Platform.OS === "android") {
    deviceLanguage = NativeModules.I18nManager.localeIdentifier;
  }

  return deviceLanguage?.replace("_", "-");
};
