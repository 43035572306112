import { canUseDOM } from "@mxmdev/react-universal-core";
import i18n, { Callback, InitOptions, Resource } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";

import common from "../translations/en-US/common.json";

import { getDeviceLanguage } from "./utils";

const DEFAULT_LNG = "en";
const DEFAULT_NS = "common";
const DEFAULT_RESOURCES = { [DEFAULT_NS]: common } as const;

const LANGUAGE_DETECTOR_DEVICE = "device";

export interface DefaultCustomTypeOptions {
  fallbackNS: typeof DEFAULT_NS;
  returnEmptyString: false;
  returnObjects: false;
}

export interface InitI18nParams {
  initOptions?: InitOptions;
  callback?: Callback;
}

interface InitI18nextParams extends InitI18nParams {
  localResources: Resource;
}

const initI18next = ({
  callback,
  initOptions,
  localResources,
}: InitI18nextParams) => {
  const { debug: debugInitOption, ...restInitOptions } = initOptions || {};

  const debug = debugInitOption ?? __DEV__;
  const ns = Object.keys(localResources[DEFAULT_LNG] || DEFAULT_RESOURCES);

  const languageDetector = new LanguageDetector();

  languageDetector.addDetector({
    lookup: getDeviceLanguage,
    name: LANGUAGE_DETECTOR_DEVICE,
  });

  i18n
    .use(ChainedBackend)
    .use(languageDetector)
    .use(initReactI18next)
    .init(
      {
        backend: {
          backends: [resourcesToBackend(localResources)],
        },
        debug,
        defaultNS: DEFAULT_NS,
        detection: {
          order: ["navigator", LANGUAGE_DETECTOR_DEVICE],
        },
        fallbackLng: DEFAULT_LNG,
        fallbackNS: DEFAULT_NS,
        ns,
        preload: canUseDOM ? undefined : Object.keys(localResources),
        react: {
          useSuspense: false,
        },
        returnEmptyString: false, // Handle the empty string added by the Crowdin integration for missing translations.
        returnObjects: false,
        ...restInitOptions,
      },
      callback
    );
};

export default initI18next;
