const {
  ACCOUNT_URL,
  API_ALLOW_GUEST_ACCESS,
  API_DEFAULT_BOT_TOKEN,
  API_DEFAULT_USER_TOKEN,
  API_KEY,
  API_ORIGIN,
  APP_URL,
  AUTH_CAPTCHA_URL,
  AUTH_REACTIVATE_URL,
  AUTH_SHARED_SIGN_IN_ENABLED,
  AUTH_SIGN_IN_URL,
  AUTH_SIGN_OUT_URL,
  AUTH_TOKEN_MIGRATE_URL,
  COM_URL,
  CURATOR_URL,
  DATO_CMS_API_TOKEN,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_CROSS_API_KEY,
  FIREBASE_CROSS_APP_ID,
  FIREBASE_CROSS_AUTH_DOMAIN,
  FIREBASE_CROSS_MEASUREMENT_ID,
  FIREBASE_CROSS_MESSAGING_SENDER_ID,
  FIREBASE_CROSS_PROJECT_ID,
  FIREBASE_CROSS_STORAGE_BUCKET_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET_ID,
  INTERCOM_APP_ID,
  MIXPANEL_PRO_PROJECT_TOKEN,
  PUBLISHER_URL,
  SENTRY_DSN,
  VIDEO_LYRICS_EMBEDDED,
} = require("./processEnv.shared");

const API_URL_SIGNATURE_ENABLED =
  process.env.NEXT_PUBLIC_API_URL_SIGNATURE_ENABLED;

const APP_ID = process.env.NEXT_PUBLIC_APP_ID;
const CLIENT_KEY = process.env.NEXT_PUBLIC_CLIENT_KEY;

const DEPLOY_ENV = process.env.NEXT_PUBLIC_DEPLOY_ENV;

const SENTRY_RELEASE_VERSION = process.env.NEXT_PUBLIC_SENTRY_RELEASE_VERSION;

const ARTIST_CLAIM_PARTNER_KEY_ID_AMUSE =
  process.env.NEXT_PUBLIC_ARTIST_CLAIM_PARTNER_KEY_ID_AMUSE;
const ARTIST_CLAIM_PARTNER_KEY_VALUE_AMUSE =
  process.env.NEXT_PUBLIC_ARTIST_CLAIM_PARTNER_KEY_VALUE_AMUSE;

const STRIPE_IDENTITY_API_KEY = process.env.NEXT_PUBLIC_STRIPE_IDENTITY_API_KEY;

module.exports = {
  ACCOUNT_URL,
  API_ALLOW_GUEST_ACCESS,
  API_DEFAULT_BOT_TOKEN,
  API_DEFAULT_USER_TOKEN,
  API_KEY,
  API_ORIGIN,
  API_URL_SIGNATURE_ENABLED,
  APP_ID,
  APP_URL,
  ARTIST_CLAIM_PARTNER_KEY_ID_AMUSE,
  ARTIST_CLAIM_PARTNER_KEY_VALUE_AMUSE,
  AUTH_CAPTCHA_URL,
  AUTH_REACTIVATE_URL,
  AUTH_SHARED_SIGN_IN_ENABLED,
  AUTH_SIGN_IN_URL,
  AUTH_SIGN_OUT_URL,
  AUTH_TOKEN_MIGRATE_URL,
  CLIENT_KEY,
  COM_URL,
  CURATOR_URL,
  DATO_CMS_API_TOKEN,
  DEPLOY_ENV,
  FIREBASE_API_KEY,
  FIREBASE_APP_ID,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_CROSS_API_KEY,
  FIREBASE_CROSS_APP_ID,
  FIREBASE_CROSS_AUTH_DOMAIN,
  FIREBASE_CROSS_MEASUREMENT_ID,
  FIREBASE_CROSS_MESSAGING_SENDER_ID,
  FIREBASE_CROSS_PROJECT_ID,
  FIREBASE_CROSS_STORAGE_BUCKET_ID,
  FIREBASE_MEASUREMENT_ID,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET_ID,
  INTERCOM_APP_ID,
  MIXPANEL_PRO_PROJECT_TOKEN,
  PUBLISHER_URL,
  SENTRY_DSN,
  SENTRY_RELEASE_VERSION,
  STRIPE_IDENTITY_API_KEY,
  VIDEO_LYRICS_EMBEDDED,
};
