// THIS FILE IS AUTOGENERATED. DO NOT CHANGE.

// @ts-nocheck
import { getI18n, Trans, useTranslation } from "react-i18next";

import { DefaultCustomTypeOptions, initI18next, InitI18nParams } from "./src";
import enCommon from "./translations/en-US/common.json";
import enLanguages from "./translations/en-US/languages.json";
import enPro from "./translations/en-US/pro.json";
import enVlyrics from "./translations/en-US/vlyrics.json";

const resources = {
  en: {
    common: enCommon,
    languages: enLanguages,
    pro: enPro,
    vlyrics: enVlyrics,
  },
} as const;

declare module "i18next" {
  interface CustomTypeOptions extends DefaultCustomTypeOptions {
    defaultNS: ["common", "languages", "pro", "vlyrics"];
    resources: typeof resources["en"];
  }
}

const initI18n = (args: InitI18nParams) =>
  initI18next({ localResources: resources, ...args });

export { getI18n, initI18n, Trans, useTranslation };
